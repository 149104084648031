import React, { useState, useEffect } from "react";

import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { Wrapper } from "./cards.style";

export default function Cards(props) {
  const { title, description, response } = props;

  return (
    <Wrapper>
      <div className="geocontainer">
        <div className="geocard">
          <div>
            <div className="geotitle">{title}</div>
            <div className="geobgparagraph">{description}</div>
            <div className="geobgparagraph">
              Then follow the following steps to get your API key:
            </div>

            <ol
              className="geobgparagraph"
              style={{ marginLeft: "30px", marginTop: "15px" }}
            >
              <li>Go to ethiomaps.com/projects..../</li>
              <li>Click on Generate Key button to generate a new API key</li>
              <li>
                Copy it and use it in your header whenever your send a request.
              </li>
            </ol>

            <div className="geobgparagraph">
              Note: Generating a new key will revoke the previous key. Make sure
              you update all instance of the api key in your project.
            </div>

            <div className="subtitle">Sample Request Header</div>
            <div className="relative h-52 w-auto flex items-start justify-start bg-[#E7E4EE] rounded-md p-2 overflow-hidden mt-6">
              <div
                className="absolute mt-10 px-2 py-2 pb-16 pl-8 overflow-y-auto max-h-full scrollbar-thin geoparag"
                style={{
                  whiteSpace: "pre-wrap",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <pre>
                  {JSON.stringify({ ETHIOMAPS_API_KEY: response }, null, 2)}
                </pre>
              </div>
            </div>
          </div>
          <div className="footer"></div>
        </div>
      </div>
    </Wrapper>
  );
}
