import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import Landing from "./containers/Page/LandingPage";
import App from "./containers/App/App";
// import Dashboard from "./containers/Dashboard/Dashboard";
import Documentation from "./containers/Documentation/Documentation";
import AdvancedSearch from "./containers/AdvancedSearch/AdvancedSearch";
import Navigation from "./containers/Navigation/Navigation";
import GeoCoding from "./containers/Geocode/geocode";
import Routing from "./containers/RoutingCont/RoutingCont";
import DistanceMatrix from "./containers/DistanceMatrix/DistanceMatrix";
import ProjectCard from "./containers/Projects/cards copy 2";
// import Dashboard02 from "./containers/Page/Dashboard02/dash02"; // Import the Dashboard02 file

// import Dash12 from "./containers/Dashboard2/dash12";
import Dash12 from "./containers/Dashboard/Dashboard";
import Signup from "./containers/Page/Signup/Signup";
import Login from "./containers/Page/Login/login";
import Newproject from "./containers/NewProject/newproject";
import Services from "./containers/Page/services";

import { connect } from "react-redux";

const RestrictedRoute = ({ element: Component, isAuthenticated, ...rest }) => {
  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ from: rest.location }} />
  );
};

const PublicRoutes = ({ isLoggedIn }) => {
  return (
    <Router basename="/apidoc">
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/ethiomaps" element={<App />}>
          <Route path="" element={<Documentation />} />
          <Route path="documentation" element={<Documentation />} />
          <Route path="navigation" element={<Navigation />} />
          <Route path="geocoding" element={<GeoCoding />} />
          <Route path="routing" element={<Routing />} />
          <Route path="distancematrix" element={<DistanceMatrix />} />
        </Route>
        <Route element={<RestrictedRoute isAuthenticated={isLoggedIn} />}>
          <Route path="ethiomaps" element={<App />}>
            <Route path="dash12" element={<Dash12 />} />
            <Route path="projects" element={<ProjectCard />} />
            <Route path="services" element={<Services />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.idToken !== null ? true : false,
  };
}

export default connect(mapStateToProps)(PublicRoutes);
