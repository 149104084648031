import React, { Component } from "react";
import { StickerWidgetWrapper } from "./style";

export default class extends Component {
  render() {
    const { fontColor, bgColor, width, title, text, createdat } = this.props;
    console.log(text);
    const textColor = {
      color: fontColor,
    };
    const widgetStyle = {
      backgroundColor: bgColor,
      width: width,
    };
    const iconStyle = {
      color: fontColor,
    };

    return (
      <StickerWidgetWrapper className="isoStickerWidget" style={widgetStyle}>
        <div className="isoContentWrapper">
          <h3 className="isoStatNumber" style={textColor}>
            {title}
          </h3>
          <div className="isoLabel" style={textColor}>
            {text}
          </div>
          <div className="HorizontalLine "></div>
          <div className="createdat ">Created at: {createdat}</div>
        </div>
      </StickerWidgetWrapper>
    );
  }
}
