import styled from "styled-components";
export const SidebarWrapper = styled.div`

.title{
    font-family: 'Poppins';
    font-size: 35px;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: center;
   
}

.description{
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
  margin: 50px auto;
  max-width: 600px;
  margin-top: -10px;
}


@media (max-width: 767px) {
  .reverse-order {
    flex-direction: column-reverse;
  }
  .description{
    padding: 10px;
  }
  
}


@media (max-width: 768px) {
  .bg-em-accent {
    border-radius: 0; 
    position: relative;
  }
  



  
}





`;