import React from 'react';
import { Button, Form, Input, notification } from 'antd';
import axios from "../../api";
import { connect } from "react-redux";

const Edit = (props) => { 
  const [form] = Form.useForm();
  const { loggedInUser } = props;
 

  const onFinish = async (values) => {
    try {
      const response = await axios.put(`https://apigateway.ethiomaps.com/usermanagemnt/changeClientPassword/${loggedInUser.id}`, values);
      notification.success({
        message: 'Password Changes Successful',
        description: 'Your password has been changed successfully.',
      });
    } catch (error) {
      console.error('Error changing password:', error); 
      const errorMessage = (error.response.data.error) ;
      notification.error({
        message: 'Password Change Failed',
        description: errorMessage,
      });
    }
  };
  
  return (
    <Form
      form={form}
      name="changePassword"
      onFinish={onFinish}
      style={{
        maxWidth: 600,
      }}
      scrollToFirstError
    >
      <Form.Item
        label="Current Password"
        name="currentPassword"
        rules={[
          {
            required: true,
            message: 'Please input your current password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="New Password"
        name="newPassword"
        rules={[
          {
            required: true,
            message: 'Please input your new password!',
          },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            message: 'Use strong password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Confirm Password"
        name="confirmPassword"
        dependencies={['newPassword']}
        rules={[
          {
            required: true,
            message: 'Please confirm your new password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item style={{ textAlign: 'center' }}>
        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
          Edit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Edit;
