import React, { useEffect, useState } from "react";
import { Table, Popconfirm } from "antd";
import axios from "../../api";
import { connect } from "react-redux";

const Services = (props) => {
  const { loggedInUser } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    if (loggedInUser && loggedInUser.Services) {
      const newData = loggedInUser.Services.map((service) => ({
        name: service.name,
        key: service.id,
        status: service.ClientService.status 
      }));
      setData(newData);
    }
  }, [loggedInUser]);

  const handleUnsubscription = async (key) => {
    try {
   
      await axios.put(`https://apigateway.ethiomaps.com/usermanagemnt/cancelSubscription/${props.loggedInUser.id}`, { serviceId: key });
      console.log('Subscription canceled successfully');
      
      window.location.reload();
    } catch (error) {
      console.error('Error canceling subscription:', error);
    }
  };

  useEffect(() => {
   
    return () => {
      
    };
  }, [props.loggedInUser.id]);

  const columns = [
    {
      title: "Service Name",
      dataIndex: "name",
      key: "name",
      width: 50,
    },
    {
      title: "Status",
      dataIndex: "status", 
      key: "status",
      width: 50,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        record.status === 'Active' ? (
          <Popconfirm
            title="Sure to Unsubscribe?"
            onConfirm={() => handleUnsubscription(record.key)}
          >
            <a style={{ color: "red" }}>Unsubscribe</a>
          </Popconfirm>
        ) : 'Unsubscribed'
      ),
      width: 50,
    }
  ];

  return (
    <div style={{ padding: "20px 80px 40px 80px" }} className=" h-dvh">
      <h1
        style={{
          textAlign: "center",
          padding: "20px",
          fontWeight: "bold",
          fontSize: "24px",
        }}
      >
        Services
      </h1>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.loggedInUser,
  };
};

export default connect(mapStateToProps)(Services);
