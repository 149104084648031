import styled, { css } from 'styled-components';


 export const Title = styled.h1`
  font-size: 33px;
  font-weight: 400;
  color: #626262;
  text-align: center;
  margin-top: -35px;
  margin-bottom: 15px; 
  display: flex;
  justify-content: center; 
  align-items: center; 
`;

export const WelcomeText = styled.p`
  font-family: 'Poppins', sans-serif; 
  font-size: 20px; 
  font-weight: 600; 
  color: #333333;
  text-align: center;
  margin-top: -2px;
 
`;
export const SignUpText = styled.p`
  font-family: 'Poppins', sans-serif; 
  text-align: center;
  font-size: 16px; 
  color: #000000; 
`;


export const HorizontalLine = styled.hr`
  width: 56%;
  border: none;
  border-top: 2px solid #e7e4ee;
  margin: 10px 0;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  `;
  export const ForgotPasswordText = styled.p`

  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 15px;

`;

