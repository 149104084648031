import styled from 'styled-components';

const WidgetWrapper = styled.div`
  margin: 0 10px;

  @media only screen and (max-width: 767) {
    margin-right: 0 !important;
  }
`;

const WidgetBox = styled.div`
  width: 100%;
  height: ${props => (props.height ? `${props.height}px` : '450px')};
  padding: ${props => (props.padding ? props.padding : '30px')};
  background-color: #ffffff;
  border: 1px solid #dadae6;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }

  #scrollableDiv {
    /* Styles for the scrollbar */
    &::-webkit-scrollbar {
      width: 2.5px;
    }
  
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: #6677cc;
    }
  }
`;

const WidgetColumn = styled.div`
  align-content: flex-start;
`;

const StickerWidgetWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  border-radius: 5px;

  .isoIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.1);

    i {
      font-size: 30px;
    }
  }

  .isoContentWrapper {
    width: 100%;
    padding: 0px 0px 20px 0px;
    display: flex;
    flex-direction: column;

    .isoStatNumber {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.1;
      margin: 0 0 5px;
      text-align: left;
      padding : 15px;
    }

    .isoLabel {
      font-size: 14px;
      font-weight: 400;
      margin-top: -30px;
      line-height: 1.2;
      padding : 15px;
    }

    .HorizontalLine{
      width: 85%;
      border: none;
      border-top: 2px solid #e7e4ee;
      margin: 10px 0;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      padding : 10px;
    }

    .createdat{
      
      font-family: Poppins;
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      letter-spacing: 0em;
      margin-left: 90px;
      

    }
  }

  
`;


export { WidgetWrapper, WidgetBox, WidgetColumn, StickerWidgetWrapper };
