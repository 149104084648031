import React, { Component } from "react";
import Card from "../../components/Documentation/cards.js";
// import Logo from "../../components/utility/logo";
class Documentation extends Component {
  render() {
    return (
      <Card
        title="Create Account"
        description="The first step to using our service is to create an account. Go to
            ethiomaps.com/signup to signup."
        response="your_api_key_goes_here"
      />
    );
  }
}

export default Documentation;
