import React, { Component } from "react";
import Card from "../../components/Cards/cards.js";
// import Logo from "../../components/utility/logo";
class GeoCoding extends Component {
  render() {
    const responses = {
      200: {
        geocoding: {
          version: "0.2",
          query: {
            text: "Arat Kilo",
            size: 10,
            layers: [
              "venue",
              "street",
              "country",
              "macroregion",
              "region",
              "county",
              "localadmin",
              "locality",
              "borough",
              "neighbourhood",
              "continent",
              "empire",
              "dependency",
              "macrocounty",
              "macrohood",
              "microhood",
              "disputed",
              "postalcode",
              "ocean",
              "marinearea",
            ],
            private: false,
            "focus.point.lat": 9.145,
            "focus.point.lon": 40.489673,
            lang: {
              name: "English",
              iso6391: "en",
              iso6393: "eng",
              via: "default",
              defaulted: true,
            },
            querySize: 20,
            parser: "pelias",
            parsed_text: {
              subject: "Arat",
              locality: "Kilo",
              admin: "Kilo",
            },
          },
          warnings: ["performance optimization: excluding 'address' layer"],
          engine: {
            name: "Pelias",
            author: "Mapzen",
            version: "1.0",
          },
          timestamp: 1710761852130,
        },
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [38.765412, 9.032137],
            },
            properties: {
              id: "node/11034209866",
              gid: "openstreetmap:venue:node/11034209866",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/11034209866",
              country_code: "ET",
              name: "Arat Kilo",
              confidence: 1,
              match_type: "exact",
              distance: 189.95,
              accuracy: "point",
              country: "Ethiopia",
              country_gid: "whosonfirst:country:85632257",
              country_a: "ETH",
              region: "Addis Ababa",
              region_gid: "whosonfirst:region:85671149",
              region_a: "AA",
              county: "Addis Ababa Zone 1",
              county_gid: "whosonfirst:county:1108695109",
              county_a: "ON",
              locality: "Addis Ababa",
              locality_gid: "whosonfirst:locality:421170391",
              continent: "Africa",
              continent_gid: "whosonfirst:continent:102191573",
              label: "Arat Kilo, Addis Ababa, Ethiopia",
              addendum: {
                osm: {
                  operator: "Addis Ababa Bus Service Enterprise",
                },
              },
            },
          },
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [38.762391, 9.033208],
            },
            properties: {
              id: "node/6718462677",
              gid: "openstreetmap:venue:node/6718462677",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/6718462677",
              country_code: "ET",
              name: "Wegagen Bank (Arat kilo Branch)",
              confidence: 1,
              match_type: "exact",
              distance: 190.273,
              accuracy: "point",
              country: "Ethiopia",
              country_gid: "whosonfirst:country:85632257",
              country_a: "ETH",
              region: "Addis Ababa",
              region_gid: "whosonfirst:region:85671149",
              region_a: "AA",
              county: "Addis Ababa Zone 1",
              county_gid: "whosonfirst:county:1108695109",
              county_a: "ON",
              locality: "Addis Ababa",
              locality_gid: "whosonfirst:locality:421170391",
              continent: "Africa",
              continent_gid: "whosonfirst:continent:102191573",
              label: "Wegagen Bank (Arat kilo Branch), Addis Ababa, Ethiopia",
            },
          },
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [38.766802, 9.032378],
            },
            properties: {
              id: "node/1727969689",
              gid: "openstreetmap:venue:node/1727969689",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/1727969689",
              country_code: "ET",
              name: "Arat kilo children and youth center",
              confidence: 1,
              match_type: "exact",
              distance: 189.796,
              accuracy: "point",
              country: "Ethiopia",
              country_gid: "whosonfirst:country:85632257",
              country_a: "ETH",
              region: "Addis Ababa",
              region_gid: "whosonfirst:region:85671149",
              region_a: "AA",
              county: "Addis Ababa Zone 1",
              county_gid: "whosonfirst:county:1108695109",
              county_a: "ON",
              locality: "Addis Ababa",
              locality_gid: "whosonfirst:locality:421170391",
              continent: "Africa",
              continent_gid: "whosonfirst:continent:102191573",
              label:
                "Arat kilo children and youth center, Addis Ababa, Ethiopia",
            },
          },
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [38.799845, 9.022596],
            },
            properties: {
              id: "node/8222985716",
              gid: "openstreetmap:venue:node/8222985716",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/8222985716",
              country_code: "ET",
              name: "Megenagna to Arat Kilo",
              confidence: 1,
              match_type: "exact",
              distance: 186.25,
              accuracy: "point",
              country: "Ethiopia",
              country_gid: "whosonfirst:country:85632257",
              country_a: "ETH",
              region: "Addis Ababa",
              region_gid: "whosonfirst:region:85671149",
              region_a: "AA",
              county: "Addis Ababa Zone 1",
              county_gid: "whosonfirst:county:1108695109",
              county_a: "ON",
              locality: "Addis Ababa",
              locality_gid: "whosonfirst:locality:421170391",
              continent: "Africa",
              continent_gid: "whosonfirst:continent:102191573",
              label: "Megenagna to Arat Kilo, Addis Ababa, Ethiopia",
            },
          },
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [38.777242, 9.057125],
            },
            properties: {
              id: "node/8223040979",
              gid: "openstreetmap:venue:node/8223040979",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/8223040979",
              country_code: "ET",
              name: "Ferensay to Arat Kilo",
              confidence: 1,
              match_type: "exact",
              distance: 188.481,
              accuracy: "point",
              country: "Ethiopia",
              country_gid: "whosonfirst:country:85632257",
              country_a: "ETH",
              region: "Addis Ababa",
              region_gid: "whosonfirst:region:85671149",
              region_a: "AA",
              county: "Addis Ababa Zone 1",
              county_gid: "whosonfirst:county:1108695109",
              county_a: "ON",
              locality: "Addis Ababa",
              locality_gid: "whosonfirst:locality:421170391",
              continent: "Africa",
              continent_gid: "whosonfirst:continent:102191573",
              label: "Ferensay to Arat Kilo, Addis Ababa, Ethiopia",
            },
          },
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [38.764607, 9.03242],
            },
            properties: {
              id: "node/8222663078",
              gid: "openstreetmap:venue:node/8222663078",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/8222663078",
              country_code: "ET",
              name: "Arat Kilo to Piassa",
              confidence: 1,
              match_type: "exact",
              distance: 190.036,
              accuracy: "point",
              country: "Ethiopia",
              country_gid: "whosonfirst:country:85632257",
              country_a: "ETH",
              region: "Addis Ababa",
              region_gid: "whosonfirst:region:85671149",
              region_a: "AA",
              county: "Addis Ababa Zone 1",
              county_gid: "whosonfirst:county:1108695109",
              county_a: "ON",
              locality: "Addis Ababa",
              locality_gid: "whosonfirst:locality:421170391",
              continent: "Africa",
              continent_gid: "whosonfirst:continent:102191573",
              label: "Arat Kilo to Piassa, Addis Ababa, Ethiopia",
            },
          },
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [38.764546, 9.032279],
            },
            properties: {
              id: "node/8222841341",
              gid: "openstreetmap:venue:node/8222841341",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/8222841341",
              country_code: "ET",
              name: "Arat Kilo to Megenagna",
              confidence: 1,
              match_type: "exact",
              distance: 190.044,
              accuracy: "point",
              country: "Ethiopia",
              country_gid: "whosonfirst:country:85632257",
              country_a: "ETH",
              region: "Addis Ababa",
              region_gid: "whosonfirst:region:85671149",
              region_a: "AA",
              county: "Addis Ababa Zone 1",
              county_gid: "whosonfirst:county:1108695109",
              county_a: "ON",
              locality: "Addis Ababa",
              locality_gid: "whosonfirst:locality:421170391",
              continent: "Africa",
              continent_gid: "whosonfirst:continent:102191573",
              label: "Arat Kilo to Megenagna, Addis Ababa, Ethiopia",
            },
          },
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [38.763084, 9.035636],
            },
            properties: {
              id: "node/8222816038",
              gid: "openstreetmap:venue:node/8222816038",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/8222816038",
              country_code: "ET",
              name: "Arat Kilo to Shiromeda",
              confidence: 1,
              match_type: "exact",
              distance: 190.179,
              accuracy: "point",
              country: "Ethiopia",
              country_gid: "whosonfirst:country:85632257",
              country_a: "ETH",
              region: "Addis Ababa",
              region_gid: "whosonfirst:region:85671149",
              region_a: "AA",
              county: "Addis Ababa Zone 1",
              county_gid: "whosonfirst:county:1108695109",
              county_a: "ON",
              locality: "Addis Ababa",
              locality_gid: "whosonfirst:locality:421170391",
              continent: "Africa",
              continent_gid: "whosonfirst:continent:102191573",
              label: "Arat Kilo to Shiromeda, Addis Ababa, Ethiopia",
            },
          },
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [38.763009, 9.036168],
            },
            properties: {
              id: "node/8222811253",
              gid: "openstreetmap:venue:node/8222811253",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/8222811253",
              country_code: "ET",
              name: "Arat kilo to Bella",
              confidence: 1,
              match_type: "exact",
              distance: 190.183,
              accuracy: "point",
              country: "Ethiopia",
              country_gid: "whosonfirst:country:85632257",
              country_a: "ETH",
              region: "Addis Ababa",
              region_gid: "whosonfirst:region:85671149",
              region_a: "AA",
              county: "Addis Ababa Zone 1",
              county_gid: "whosonfirst:county:1108695109",
              county_a: "ON",
              locality: "Addis Ababa",
              locality_gid: "whosonfirst:locality:421170391",
              continent: "Africa",
              continent_gid: "whosonfirst:continent:102191573",
              label: "Arat kilo to Bella, Addis Ababa, Ethiopia",
            },
          },
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [38.762956, 9.036445],
            },
            properties: {
              id: "node/8222814567",
              gid: "openstreetmap:venue:node/8222814567",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/8222814567",
              country_code: "ET",
              name: "Arat KIlo to Ferensai",
              confidence: 1,
              match_type: "exact",
              distance: 190.187,
              accuracy: "point",
              country: "Ethiopia",
              country_gid: "whosonfirst:country:85632257",
              country_a: "ETH",
              region: "Addis Ababa",
              region_gid: "whosonfirst:region:85671149",
              region_a: "AA",
              county: "Addis Ababa Zone 1",
              county_gid: "whosonfirst:county:1108695109",
              county_a: "ON",
              locality: "Addis Ababa",
              locality_gid: "whosonfirst:locality:421170391",
              continent: "Africa",
              continent_gid: "whosonfirst:continent:102191573",
              label: "Arat KIlo to Ferensai, Addis Ababa, Ethiopia",
            },
          },
        ],
        bbox: [38.762391, 9.022596, 38.799845, 9.057125],
      },
      400: {
        error: "API_KEY and service query parameters are required",
        code: "Bad Request",
      },

      401: {
        error: "Unauthorized - Invalid API key",
        code: "Unauthorized",
      },
    };

    const codes = {
      html: {
        status: "geocoding",
        data: [
          {
            service_id: "12345",
            service_name: "Arat Kilo Service 1",
            description: "This is a sample service at Arat Kilo.",
            location: "Arat Kilo, Addis Ababa",
            rating: 4.5,
            reviews: 20,
            category: "Food",
            price_range: "$$",
            hours_of_operation: "Mon-Sun: 9:00 AM - 10:00 PM",
            contact: "Phone: (123) 456-7890",
            website: "http://www.sample-service.com",
          },
          {
            service_id: "67890",
            service_name: "Arat Kilo Service 2",
            description: "Another sample service in Arat Kilo.",
            location: "Arat Kilo, Addis Ababa",
            rating: 4.0,
            reviews: 15,
            category: "Retail",
            price_range: "$$$",
            hours_of_operation: "Mon-Sat: 10:00 AM - 8:00 PM",
            contact: "Phone: (234) 567-8901",
            website: "http://www.another-service.com",
          },
          {
            service_id: "1",
            service_name: "Service 1",
            description: "This is service 1.",
            location: "Location 1",
            rating: 4.5,
            reviews: 20,
            category: "Category A",
            price_range: "$$",
            hours_of_operation: "Mon-Sun: 9:00 AM - 10:00 PM",
            contact: "Phone: (123) 456-7890",
            website: "http://www.service1.com",
          },
          {
            service_id: "2",
            service_name: "Service 2",
            description: "This is service 2.",
            location: "Location 2",
            rating: 4.0,
            reviews: 15,
            category: "Category B",
            price_range: "$$$",
            hours_of_operation: "Mon-Sat: 10:00 AM - 8:00 PM",
            contact: "Phone: (234) 567-8901",
            website: "http://www.service2.com",
          },
          // Continue adding more entries here...
          {
            service_id: "421",
            service_name: "Service 421",
            description: "This is service 421.",
            location: "Location 421",
            rating: 3.5,
            reviews: 10,
            category: "Category D",
            price_range: "$$$",
            hours_of_operation: "Mon-Fri: 8:00 AM - 6:00 PM",
            contact: "Phone: (987) 654-3210",
            website: "http://www.service421.com",
          },
        ],
      },
      javascript: {
        totalDistance: 71,
        msg: "- Geocoding",
      },
    };

    return (
      <Card
        title="Geocoding"
        description=" The Geo Card feature will include: search suggestions, autocomplete Search by service area, Search nearby services, search by category, combined search, search by coordinates, Local information."
        apiButtonText="https://apigateway.ethiomaps.com/apigateway/services?API_KEY=14529b5d-a679-4046-b8be-cb32e33b4c60&service=search&text=Arat%20Kilo"
        samplereqText="https://apigateway.ethiomaps.com/apigateway/services?API_KEY=14529b5d-a679-4046-b8be-cb32e33b4c60&service=search&text=Arat%20Kilo"
        responses={responses}
        codes={codes}
      />
    );
  }
}

export default GeoCoding;
