import React, { Component } from "react";
import Card from "../../components/Cards/cards.js";
// import Logo from "../../components/utility/logo";
class Routing extends Component {
  render() {
    const responses = {
      200: {
        code: "Ok",
        routes: [
          {
            geometry: {
              coordinates: [
                [38.786933, 9.01557],
                [38.787459, 9.015571],
                [38.789225, 9.016132],
                [38.793418, 9.017049],
                [38.798636, 9.018715],
                [38.807372, 9.020327],
                [38.808899, 9.020358],
                [38.811695, 9.019828],
                [38.81328, 9.019712],
              ],
              type: "LineString",
            },
            legs: [
              {
                steps: [
                  {
                    geometry: {
                      coordinates: [
                        [38.786933, 9.01557],
                        [38.787224, 9.015616],
                      ],
                      type: "LineString",
                    },
                    maneuver: {
                      bearing_after: 81,
                      bearing_before: 0,
                      location: [38.786933, 9.01557],
                      modifier: "right",
                      type: "depart",
                    },
                    mode: "driving",
                    driving_side: "right",
                    name: "",
                    intersections: [
                      {
                        out: 0,
                        entry: [true],
                        bearings: [81],
                        location: [38.786933, 9.01557],
                      },
                    ],
                    weight: 2,
                    duration: 2,
                    distance: 32.4,
                  },
                  {
                    geometry: {
                      coordinates: [
                        [38.787224, 9.015616],
                        [38.787282, 9.015581],
                        [38.787368, 9.015565],
                        [38.787459, 9.015571],
                        [38.787517, 9.015596],
                        [38.787581, 9.015633],
                        [38.787648, 9.015716],
                      ],
                      type: "LineString",
                    },
                    maneuver: {
                      exit: 2,
                      bearing_after: 120,
                      bearing_before: 80,
                      location: [38.787224, 9.015616],
                      modifier: "right",
                      type: "rotary",
                    },
                    mode: "driving",
                    driving_side: "right",
                    name: "",
                    intersections: [
                      {
                        out: 0,
                        in: 1,
                        entry: [true, false, false],
                        bearings: [120, 255, 315],
                        location: [38.787224, 9.015616],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [105, 180, 300],
                        location: [38.787282, 9.015581],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, false, false],
                        bearings: [60, 195, 270],
                        location: [38.787459, 9.015571],
                      },
                    ],
                    rotary_name: "Equatorial Guinea Street",
                    weight: 3.1,
                    duration: 3.1,
                    distance: 54,
                  },
                  {
                    geometry: {
                      coordinates: [
                        [38.787648, 9.015716],
                        [38.787852, 9.015777],
                        [38.788055, 9.015818],
                        [38.7885, 9.015913],
                        [38.788902, 9.015991],
                        [38.78894, 9.016],
                        [38.78916, 9.016058],
                        [38.789188, 9.016078],
                        [38.789225, 9.016132],
                      ],
                      type: "LineString",
                    },
                    maneuver: {
                      exit: 2,
                      bearing_after: 73,
                      bearing_before: 37,
                      location: [38.787648, 9.015716],
                      modifier: "slight right",
                      type: "exit rotary",
                    },
                    mode: "driving",
                    driving_side: "right",
                    name: "",
                    intersections: [
                      {
                        out: 1,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [0, 75, 225],
                        location: [38.787648, 9.015716],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [75, 150, 255],
                        location: [38.788902, 9.015991],
                      },
                    ],
                    weight: 10.1,
                    duration: 10.1,
                    distance: 181.6,
                  },
                  {
                    geometry: {
                      coordinates: [
                        [38.789225, 9.016132],
                        [38.789752, 9.016246],
                        [38.790376, 9.016381],
                        [38.791997, 9.016739],
                        [38.793418, 9.017049],
                        [38.793548, 9.017086],
                        [38.793714, 9.017136],
                        [38.794243, 9.017308],
                        [38.794744, 9.017469],
                        [38.795041, 9.017581],
                        [38.795071, 9.017591],
                        [38.795343, 9.017675],
                        [38.795563, 9.017737],
                        [38.796247, 9.017932],
                        [38.797031, 9.018176],
                        [38.798274, 9.018601],
                        [38.798636, 9.018715],
                      ],
                      type: "LineString",
                    },
                    maneuver: {
                      bearing_after: 77,
                      bearing_before: 50,
                      location: [38.789225, 9.016132],
                      modifier: "straight",
                      type: "turn",
                    },
                    mode: "driving",
                    driving_side: "right",
                    name: "Equatorial Guinea Street",
                    intersections: [
                      {
                        out: 0,
                        in: 1,
                        entry: [true, false, false],
                        bearings: [75, 225, 255],
                        location: [38.789225, 9.016132],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [75, 165, 255],
                        location: [38.790376, 9.016381],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [75, 150, 255],
                        location: [38.791997, 9.016739],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [75, 165, 255],
                        location: [38.793418, 9.017049],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [75, 165, 255],
                        location: [38.794243, 9.017308],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [69, 78, 252],
                        location: [38.794744, 9.017469],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, false, false],
                        bearings: [75, 195, 255],
                        location: [38.795343, 9.017675],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [75, 150, 255],
                        location: [38.796247, 9.017932],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [75, 180, 255],
                        location: [38.798274, 9.018601],
                      },
                    ],
                    weight: 66.3,
                    duration: 66.3,
                    distance: 1074.8,
                  },
                  {
                    geometry: {
                      coordinates: [
                        [38.798636, 9.018715],
                        [38.798799, 9.018726],
                        [38.79943, 9.018857],
                        [38.800588, 9.019071],
                        [38.800734, 9.019098],
                        [38.800821, 9.01912],
                        [38.801438, 9.019232],
                      ],
                      type: "LineString",
                    },
                    maneuver: {
                      bearing_after: 85,
                      bearing_before: 71,
                      location: [38.798636, 9.018715],
                      modifier: "slight right",
                      type: "continue",
                    },
                    mode: "driving",
                    driving_side: "right",
                    name: "Equatorial Guinea Street",
                    intersections: [
                      {
                        out: 1,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [75, 90, 255],
                        location: [38.798636, 9.018715],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [75, 180, 255],
                        location: [38.79943, 9.018857],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [75, 165, 255],
                        location: [38.800734, 9.019098],
                      },
                    ],
                    weight: 17.3,
                    duration: 17.3,
                    distance: 313.5,
                  },
                  {
                    geometry: {
                      coordinates: [
                        [38.801438, 9.019232],
                        [38.80151, 9.019204],
                        [38.801628, 9.019181],
                        [38.801748, 9.019191],
                        [38.80186, 9.019234],
                        [38.801971, 9.019315],
                      ],
                      type: "LineString",
                    },
                    maneuver: {
                      exit: 2,
                      bearing_after: 111,
                      bearing_before: 78,
                      location: [38.801438, 9.019232],
                      modifier: "slight right",
                      type: "rotary",
                    },
                    mode: "driving",
                    driving_side: "right",
                    name: "",
                    intersections: [
                      {
                        out: 0,
                        in: 1,
                        entry: [true, false, false],
                        bearings: [105, 255, 300],
                        location: [38.801438, 9.019232],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [105, 150, 285],
                        location: [38.80151, 9.019204],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, false, false],
                        bearings: [60, 165, 255],
                        location: [38.80186, 9.019234],
                      },
                    ],
                    rotary_name: "Adwa Square",
                    weight: 3.4,
                    duration: 3.4,
                    distance: 63.3,
                  },
                  {
                    geometry: {
                      coordinates: [
                        [38.801971, 9.019315],
                        [38.802462, 9.01943],
                        [38.802924, 9.019505],
                        [38.803365, 9.019592],
                        [38.803465, 9.019613],
                        [38.803545, 9.019629],
                        [38.804102, 9.019752],
                        [38.804558, 9.019843],
                        [38.806525, 9.020177],
                        [38.806655, 9.020199],
                        [38.807372, 9.020327],
                        [38.807617, 9.020363],
                        [38.807704, 9.020378],
                        [38.808315, 9.0204],
                        [38.808899, 9.020358],
                        [38.809363, 9.020287],
                        [38.809637, 9.020232],
                        [38.810139, 9.02013],
                        [38.811019, 9.019945],
                        [38.811621, 9.019841],
                        [38.811695, 9.019828],
                        [38.812228, 9.019774],
                      ],
                      type: "LineString",
                    },
                    maneuver: {
                      exit: 2,
                      bearing_after: 75,
                      bearing_before: 53,
                      location: [38.801971, 9.019315],
                      modifier: "slight right",
                      type: "exit rotary",
                    },
                    mode: "driving",
                    driving_side: "right",
                    name: "",
                    intersections: [
                      {
                        out: 1,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [30, 75, 240],
                        location: [38.801971, 9.019315],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, false, false],
                        bearings: [75, 240, 255],
                        location: [38.802462, 9.01943],
                      },
                      {
                        out: 0,
                        in: 1,
                        entry: [true, false, false],
                        bearings: [75, 255, 285],
                        location: [38.803365, 9.019592],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [75, 165, 255],
                        location: [38.803465, 9.019613],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, false, false],
                        bearings: [75, 165, 255],
                        location: [38.803545, 9.019629],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [75, 105, 255],
                        location: [38.804558, 9.019843],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, false, false],
                        bearings: [75, 240, 255],
                        location: [38.807617, 9.020363],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [90, 195, 255],
                        location: [38.807704, 9.020378],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [90, 195, 270],
                        location: [38.808315, 9.0204],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [105, 195, 270],
                        location: [38.808899, 9.020358],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [105, 195, 285],
                        location: [38.809637, 9.020232],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [105, 180, 285],
                        location: [38.810139, 9.02013],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [105, 195, 285],
                        location: [38.811019, 9.019945],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [105, 195, 285],
                        location: [38.811621, 9.019841],
                      },
                      {
                        out: 0,
                        in: 2,
                        entry: [true, false, false],
                        bearings: [90, 195, 285],
                        location: [38.811695, 9.019828],
                      },
                    ],
                    weight: 63.5,
                    duration: 63.5,
                    distance: 1144.9,
                  },
                  {
                    geometry: {
                      coordinates: [
                        [38.812228, 9.019774],
                        [38.81328, 9.019712],
                      ],
                      type: "LineString",
                    },
                    maneuver: {
                      bearing_after: 92,
                      bearing_before: 95,
                      location: [38.812228, 9.019774],
                      modifier: "straight",
                      type: "new name",
                    },
                    mode: "driving",
                    driving_side: "right",
                    name: "",
                    intersections: [
                      {
                        out: 0,
                        in: 2,
                        entry: [true, true, false],
                        bearings: [90, 180, 270],
                        location: [38.812228, 9.019774],
                      },
                    ],
                    weight: 6.3,
                    duration: 6.3,
                    distance: 115.9,
                  },
                  {
                    geometry: {
                      coordinates: [
                        [38.81328, 9.019712],
                        [38.81328, 9.019712],
                      ],
                      type: "LineString",
                    },
                    maneuver: {
                      bearing_after: 0,
                      bearing_before: 93,
                      location: [38.81328, 9.019712],
                      type: "arrive",
                    },
                    mode: "driving",
                    driving_side: "right",
                    name: "",
                    intersections: [
                      {
                        in: 0,
                        entry: [true],
                        bearings: [273],
                        location: [38.81328, 9.019712],
                      },
                    ],
                    weight: 0,
                    duration: 0,
                    distance: 0,
                  },
                ],
                summary: "Equatorial Guinea Street, Adwa Square",
                weight: 172,
                duration: 172,
                distance: 2980.4,
              },
            ],
            weight_name: "routability",
            weight: 172,
            duration: 172,
            distance: 2980.4,
          },
        ],
        waypoints: [
          {
            hint: "eccIgP___38ZAAAALAAAAAAAAAAAAAAABrU5QhaXAUIAAAAAAAAAABkAAAAsAAAAAAAAAAAAAAAlAAAAdddPAhKRiQCU108CUZCJAAAADwUAAAAA",
            distance: 21.6165216,
            name: "",
            location: [38.786933, 9.01557],
          },
          {
            hint: "wGEAgP___38_AAAAQAAAAAAAAAAFAAAA6b3nQq4t4T0AAAAA_9EaQT8AAABAAAAAAAAAAAUAAAAlAAAAYD5QAkChiQBePlACHqGJAAAA3wUAAAAA",
            distance: 3.766878616,
            name: "",
            location: [38.81328, 9.019712],
          },
        ],
      },
      400: [
        {
          message: "Query string malformed close to position 74",
          code: "InvalidQuery",
        },
        {
          message: "Number of coordinates needs to be at least two.",
          code: "InvalidOptions",
        },
      ],
      401: {
        error: "Unauthorized - Invalid API key",
        code: "Unauthorized",
      },
    };

    const codes = {
      html: {
        status: "Routing",
        data: [
          {
            service_id: "12345",
            service_name: "Arat Kilo Service 1",
            description: "This is a sample service at Arat Kilo.",
            location: "Arat Kilo, Addis Ababa",
            rating: 4.5,
            reviews: 20,
            category: "Food",
            price_range: "$$",
            hours_of_operation: "Mon-Sun: 9:00 AM - 10:00 PM",
            contact: "Phone: (123) 456-7890",
            website: "http://www.sample-service.com",
          },
          {
            service_id: "67890",
            service_name: "Arat Kilo Service 2",
            description: "Another sample service in Arat Kilo.",
            location: "Arat Kilo, Addis Ababa",
            rating: 4.0,
            reviews: 15,
            category: "Retail",
            price_range: "$$$",
            hours_of_operation: "Mon-Sat: 10:00 AM - 8:00 PM",
            contact: "Phone: (234) 567-8901",
            website: "http://www.another-service.com",
          },
          {
            service_id: "1",
            service_name: "Service 1",
            description: "This is service 1.",
            location: "Location 1",
            rating: 4.5,
            reviews: 20,
            category: "Category A",
            price_range: "$$",
            hours_of_operation: "Mon-Sun: 9:00 AM - 10:00 PM",
            contact: "Phone: (123) 456-7890",
            website: "http://www.service1.com",
          },
          {
            service_id: "2",
            service_name: "Service 2",
            description: "This is service 2.",
            location: "Location 2",
            rating: 4.0,
            reviews: 15,
            category: "Category B",
            price_range: "$$$",
            hours_of_operation: "Mon-Sat: 10:00 AM - 8:00 PM",
            contact: "Phone: (234) 567-8901",
            website: "http://www.service2.com",
          },
          // Continue adding more entries here...
          {
            service_id: "421",
            service_name: "Service 421",
            description: "This is service 421.",
            location: "Location 421",
            rating: 3.5,
            reviews: 10,
            category: "Category D",
            price_range: "$$$",
            hours_of_operation: "Mon-Fri: 8:00 AM - 6:00 PM",
            contact: "Phone: (987) 654-3210",
            website: "http://www.service421.com",
          },
        ],
      },
      javascript: {
        totalDistance: 71,
        msg: "- Routing",
      },
    };

    return (
      <Card
        title="Routing"
        description="The Routing feature will include: search suggestions, autocomplete Search by service area, Search nearby services, search by category, combined search, search by coordinates, Local information "
        apiButtonText="https://apigateway.ethiomaps.com/apigateway/services?API_KEY=14529b5d-a679-4046-b8be-cb32e33b4c60&service=routing&text=38.764152,9.034507;38.760133,8.13383"
        samplereqText="https://apigateway.ethiomaps.com/apigateway/services?API_KEY=14529b5d-a679-4046-b8be-cb32e33b4c60&service=routing&text=38.764152,9.034507;38.760133,8.13383"
        responses={responses}
        codes={codes}
      />
    );
  }
}

export default Routing;
